import useSWR from 'swr';

// Components
import SkeletonSectionHeadings from '../../components/SectionHeadings/SkeletonSectionHeadings';
import SkeletonSeriesGridItems from '../../components/listGridItems/SeriesGridItems/Skeleton';
import SkeletonSeriesSearchGridItems from '../../components/listGridItems/SeriesGridItems/Skeleton-Search';
import SeriesGrid from '../../components/SeriesGrid/SeriesGrid';
import NoResultsFound from '../../components/NoResultsFound/NoResultsFound';

// Libraries
import swrUrlParser from '../swrUrlParser/swrUrlParser';
import useSWRFetcher from '../useSWRFetcher';

// Summit API URL.
const seriesApiUrl = '/api/series';

export const FetchSeries = (baseUrl, params, componentInfo, state = true) => {

  const fullUrl = swrUrlParser(seriesApiUrl, params);

  const { data, error } = useSWR(state ? fullUrl : null, useSWRFetcher());

  let seriesData;

  if (error) {
    seriesData = {
      series: [],
      found: 0,
    };
  }

  if (!data || data['summits']?.length === 0) {
    seriesData = {
      series: [],
      found: 0,
    };
  } else {
    seriesData = {
      series: data['summits'],
      found: data['found'],
    };
  }

  return (
    <>
      {(() => {
        if (!data) {
          if(componentInfo?.pageType === 'search-page'){
            return (
              <>
                <SkeletonSeriesSearchGridItems />
              </>
            );
          } else {
            return (
              <>
                <SkeletonSectionHeadings
                  skeletonHeadingDescription={false}
                  headingBlockName={componentInfo?.headingBlockName}
                />
                <SkeletonSeriesGridItems />
              </>
            );
          }
        } else if (seriesData['series']?.length > 0) {
          return (
            <>
              <SeriesGrid
                  SeriesGrid={seriesData['series']}
                  found={seriesData['found']}
                  type={'series'}
                  filter={'upcoming'}
                  footLinkTitle={componentInfo?.footLinkTitle}
                  gridItemName={componentInfo?.gridItemName}
                  utmParams={componentInfo?.seriesUTM}
                  rowColSm={componentInfo?.rowColSm}
                  rowColMd={componentInfo?.rowColMd}
                  rowColXl={componentInfo?.rowColXl}
                  seriesFilterParams={params}
                  dataBddTitle={componentInfo?.dataBddTitle}
                  dataBddDesc={componentInfo?.dataBddDesc}
                  summitUrl={componentInfo?.summitUrl}
                  seriesUrl={componentInfo?.seriesUrl}
                  baseUrl={baseUrl}
                  priorityStatus={componentInfo?.priorityStatus}
                  headingBlockName={componentInfo?.headingBlockName}
                  sectionHeading={componentInfo?.sectionHeading}
                  sectionHeadingTitle={componentInfo?.sectionHeadingTitle}
                  sectionHeadingBddTitle={componentInfo?.sectionHeadingBddTitle}
                  sectionHeadingDescription={componentInfo?.sectionHeadingDescription} // set as empty to disable description
                  sectionHeadingLink={componentInfo?.sectionHeadingLink}
                  sectionHeadingLinkText={componentInfo?.sectionHeadingLinkText}
                  sectionHeadingBddDesc={componentInfo?.sectionHeadingBddTitle}
                  sectionHeadingBddLink={componentInfo?.sectionHeadingBddLink}
                  skeletonHeadingDescription={false}
                  isFirstBlock={false}
                  listLayout={componentInfo?.listLayout}
                  cardLayout={componentInfo?.cardLayout}
                  pageType={componentInfo?.pageType}
                  clickableCard={componentInfo?.clickableCard}
                />
            </>
          );
        } else if (seriesData['series']?.length < 1) {
          if(componentInfo?.pageType === 'search-page'){
            return (
            <>
              <NoResultsFound searchKey={params?.q} />
            </>
            );
          }else{
            return (<></>);
          }
        }
      })()}
    </>
  );
};

export default FetchSeries;
