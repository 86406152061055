import React, { FC } from 'react';
import { Row } from 'reactstrap';
import styles from './index.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';

interface BreadcrumbsProps {
  parentTitle: string;
  parentHref: string;
  childTitle: string;
}

const Breadcrumbs: FC<BreadcrumbsProps> = ({
  parentTitle = 'Parent Title',
  parentHref = '/',
  childTitle = 'Child Title',
}) => {
return (
  <>
    <Row>
      <div className={styles['breadcrumbs-container']}>
        <p className={styles['breadcrumbs-parent']}>
          <a className={styles['breadcrumbs-parent-title']} href={parentHref}>{parentTitle}</a>
          <FontAwesomeIcon icon={faChevronRight} className={styles['faChevronRight-icon']} />
          <span className={styles['breadcrumbs-child-title']}>{childTitle}</span>
        </p>
      </div>
    </Row>
  </>
)}

export default Breadcrumbs;
