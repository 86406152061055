import useSWR from 'swr';

// Components
import SkeletonSectionHeadings from '../../components/SectionHeadings/SkeletonSectionHeadings';
import SkeletonTalksGridItems from '../../components/listGridItems/TalksGridItems/Skeleton';
import TalksGrid from '../../components/TalksGrid/TalksGrid';

// Libraries
import removeDuplications from '../removeDuplications/removeDuplications';
import swrUrlParser from '../swrUrlParser/swrUrlParser';
import useSWRFetcher from '../useSWRFetcher';

// Webcast API URL.
const webcastUrl = '/api/webcasts';

const LatestTalks = (hostUrl, params, componentInfo, state = true) => {

  const fullUrl = swrUrlParser(webcastUrl, params);

  const { data, error } = useSWR(state ? fullUrl : null, useSWRFetcher());

  if (error) return <></>;

  let talksData;

  if (!data || data['communications']?.length === 0) {
    talksData = {
      talks: [],
      found: 0,
    };
  } else {
    // Filter dupes talks from the list.
    const talkCommunications = removeDuplications(data['communications']);
    talksData = {
      talks: talkCommunications,
      found: data['found']
    };
  }

  return (
    <>
      {(() => {
        if (!data) {
          return (
            <>
              <SkeletonSectionHeadings
                skeletonHeadingDescription={false}
                headingBlockName={''}
              />
              <SkeletonTalksGridItems />
            </>
          );
        } else if (talksData['talks']?.length > 0) {
          return (
            <TalksGrid
              footLinkTitle={''}
              latestTalksGrid={talksData['talks']}
              foundTalks={talksData['found']}
              gridItemName={'pop-talks-item'}
              rowColSm={6}
              rowColLg={4}
              rowColXl={3}
              cardLayout={'vertical-card'}
              companyLogo={true}
              utmParams={componentInfo?.talksUTM}
              talksFilterParams={componentInfo?.talksFilterParams}
              baseUrl={hostUrl}
              headingBlockName={'latest-talks-block'}
              sectionHeading={true}
              sectionHeadingTitle={componentInfo?.sectionHeadingTitle}
              sectionHeadingDescription={
                componentInfo?.sectionHeadingDescription
              }
              sectionHeadingLink={componentInfo?.sectionHeadingLink}
              sectionHeadingLinkText={componentInfo?.sectionHeadingLinkText}
              sectionHeadingBddTitle={componentInfo?.sectionHeadingBddTitle}
              sectionHeadingBddDesc={componentInfo?.sectionHeadingBddDesc}
              sectionHeadingBddLink={componentInfo?.sectionHeadingBddLink}
              skeletonHeadingDescription={componentInfo?.skeletonHeadingDescription}
              priorityStatus={componentInfo?.priorityStatus}
              //isFirstBlock={ headingClassLive }
            />
          );
        } else if (talksData['talks']?.length < 1) {
          <></>;
        }
      })()}
    </>
  );
};

export default LatestTalks;
