import React, { FC, useState, useEffect } from 'react';
import SectionHeading from '../SectionHeadings/SectionHeadings';
import SkeletonSectionHeadings from '../../components/SectionHeadings/SkeletonSectionHeadings';
import SkeletonSeriesGridItems from '../listGridItems/SeriesGridItems/Skeleton';
import SeriesGridItems from '../listGridItems/SeriesGridItems/SeriesGridItems';
import LoadMore from '../LoadMore/LoadMore';
import getFetch from '../../libs/getFetch';

interface SeriesProps {
  SeriesGrid: SummitsArray[];
  found: number;
  filter?: 'live' | 'upcoming' | 'recorded';
  type: 'summits' | 'series';
  listLayout?: 'horizontal-list' | 'vertical-list'; // we don't want to pass this for talks grid homepage
  cardLayout?: 'horizontal-card' | 'vertical-card';
  clickableCard?: boolean;
  footLinkTitle?: string;
  gridItemName?: string;
  pageType?: string; // needed to pass into the image comp to try and format different resolutions.
  rowColSm?: number;
  rowColMd?: number;
  rowColLg?: number;
  rowColXl?: number;
  utmParams?: string;
  seriesFilterParams?: SeriesFilterParamsArray;
  listStyle?: string;
  dataBddTitle?: string;
  dataBddDesc?: string;
  summitUrl: string;
  seriesUrl: string;
  baseUrl: string;
  priorityStatus?: boolean;
  sectionHeading: true | false;
  headingBlockName?: string;
  sectionHeadingTitle?: string;
  sectionHeadingDescription?: string;
  skeletonHeadingDescription?: boolean;
  sectionHeadingLink?: string;
  sectionHeadingLinkText?: string;
  sectionHeadingBddTitle?: string;
  sectionHeadingBddDesc?: string;
  sectionHeadingBddLink?: string;
  isFirstBlock?: boolean;
}

export interface SeriesFilterParamsArray {
  size?: number;
  bq?: string;
  rank?: string;
  rankClosest?: string;
}

export interface SummitsArray {
  id: number;
  title: string;
  description: string;
  scheduledStartDate: Date;
  scheduledEndDate: Date;
  visibility: 'public' | 'private';
  imageUrl: string;
  companyLogo: string;
  primaryColor: [];
  secondaryColor: [];
  tracks: [];
  featured: boolean;
  wordPressLink: string;
}

const SeriesGrid: FC<SeriesProps> = ({
  SeriesGrid,
  found,
  filter,
  type,
  listLayout,
  cardLayout,
  clickableCard,
  footLinkTitle,
  gridItemName,
  pageType,
  rowColSm,
  rowColMd,
  rowColLg,
  rowColXl,
  utmParams,
  seriesFilterParams,
  dataBddTitle,
  dataBddDesc,
  summitUrl,
  seriesUrl,
  baseUrl,
  priorityStatus,
  sectionHeading,
  headingBlockName,
  sectionHeadingTitle,
  sectionHeadingDescription,
  skeletonHeadingDescription,
  sectionHeadingLink,
  sectionHeadingLinkText,
  sectionHeadingBddTitle,
  sectionHeadingBddDesc,
  sectionHeadingBddLink,
  isFirstBlock,
}) => {
  let defaultSize;

  if (seriesFilterParams?.['size'] > 0 || seriesFilterParams?.['size'] !== undefined) {
    defaultSize = Number(seriesFilterParams['size']);
  } else {
    defaultSize = 6;
  }

  const [seriesData, setSeriesData] = useState(SeriesGrid);
  const [seriesCount, setSeriesCount] = useState<number>(defaultSize);
  const [showLoadMoreBtn, setShowLoadMoreBtn] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const getSeries = async () => {
      setIsLoading(SeriesGrid && false);
    };
    getSeries();
  });

  const url = '/api/series';

  const loadMoreBtn = async () => {
    setSeriesCount((prevValue) => prevValue + 6);

    setShowLoadMoreBtn(true);

    // Fetch new set of series.
    const resJson = await getFetch(baseUrl + url, {
      start: seriesCount,
      size: defaultSize,
      bq: seriesFilterParams['bq'],
      rank: seriesFilterParams['rank'],
      rankClosest: seriesFilterParams['rankClosest'],
    });

    // Push new set of data into existing array. Using JS spread operator.
    setSeriesData((oldTalksData) => [...oldTalksData, ...resJson['summits']]);

    // Turn off loading spinner
    setShowLoadMoreBtn(false);
  };

  // Hide load more if visible is more less or equals to found talks
  let displayLoadMore;

  if (found > seriesCount  && SeriesGrid?.length > 0) {
    displayLoadMore = true;
  } else {
    displayLoadMore = false;
  }

   return <div className={'grid-box'} data-bdd="series-grid">

   {(() => {

    if ( sectionHeading == true ) {

      if ( isLoading ) {

       return (

        <SkeletonSectionHeadings
         skeletonHeadingDescription={skeletonHeadingDescription}
         {... (isFirstBlock == true &&  {classes: "no-margin-top" } ) }
        />
       );

      } else if  ( SeriesGrid?.length > 0 ) {

       return (

         <SectionHeading
          title={sectionHeadingTitle}
          description={sectionHeadingDescription}
          link={sectionHeadingLink}
          linkTitle={sectionHeadingLinkText}
          headingBlockName={headingBlockName}
          dataBddTitle={sectionHeadingBddTitle}
          dataBddDesc={sectionHeadingBddDesc}
          dataBddLink={sectionHeadingBddLink}
          {... (isFirstBlock == true &&  {classes: "no-margin-top" } ) }
          />
        );

      } else {
       <></>
      }
     }

   })()}

   {(() => {

     if ( isLoading )  {

       return (
        <SkeletonSeriesGridItems />
       );

     } else if ( SeriesGrid?.length > 0 ) {

      return (
       <>

        <SeriesGridItems
          type={'series'} // this can be hardcoded as it's a series comp only.
          gridData={seriesData}
          rowColSm={rowColSm}
          rowColMd={rowColMd}
          rowColLg={rowColLg}
          rowColXl={rowColXl}
          footLinkTitle={footLinkTitle}
          gridItemName={gridItemName}
          listStyle={'card'}
          listLayout={listLayout}
          cardLayout={cardLayout}
          clickableCard={clickableCard}
          pageType={pageType}
          utmParams={utmParams}
          dataBddTitle={dataBddTitle}
          dataBddDesc={dataBddDesc}
          summitUrl={summitUrl}
          seriesUrl={seriesUrl}
          priorityStatus={priorityStatus}
        />

        <LoadMore
         onClick={loadMoreBtn}
         loader={showLoadMoreBtn}
         showLoadMore={displayLoadMore}
         gridItemName={gridItemName}
        />
       </>
      );

    } else {
      <></>
    }

   })()}

  </div>
};

export default SeriesGrid;
