import React, { FC } from 'react'
import {Row, Col, Card, CardTitle, CardText, CardLink } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-light-svg-icons'
import styles from './index.module.scss'

interface BusinessCardProps {

  title?: string
  description?: string
  link?: string
}

const BusinessCard: FC<BusinessCardProps> = ({ title, description, link }) => {

  return (
   <>
   <CardLink href={link} target="_blank">
      <Card className={styles['business-card']} body>
        <Row className='align-items-center'>
          <Col className="col-10">
            <CardTitle className={styles['business-title']} tag="h2">{title}</CardTitle>
            <CardText className={styles['business-description']}>{description}</CardText>
          </Col>
          <Col className="col-2 text-end">
            <FontAwesomeIcon className={styles['business-fa-chevron-right']} icon={faChevronRight} />
          </Col>
        </Row>
      </Card>
    </CardLink>
   </>
  );
}

export default BusinessCard
