import React from 'react';

import {
  Row,
  Col,
  CardDeck,
  Card,
  CardBody,
  CardTitle,
  CardText,
} from 'reactstrap';
import classnames from 'classnames';

import styles from './skeleton.module.scss';
<div className={styles['body-comp-logo']}></div>;

const SkeletonSeriesSearchGridItems = () => {
  const counter = 10;
  return (
    <>
      {(() => {
        const SkeletonCard = [];
        for (let i = 1; i <= counter; i++) {
          SkeletonCard.push(
            <Row key={`SkeletonItem-series-row-` + i} className={classnames(styles['vertical-list'], 'g-4')}>
              <Col sm='12'>
                <CardDeck className={classnames('h-100')}>
                  <Card className={classnames(styles['horizontal-card'], styles['search-series-item'], 'h100')}>
                    <div key={`SkeletonImg-series-wrap-` + i} className={classnames(styles['img-wrap'], styles['search-img-wrap'])}></div>
                    <CardBody className={styles['cardbody']}>
                      <div className={styles['main-body']}>
                        <CardTitle key={`SkeletonItem-series-title-` + i} className={styles['item-title']}></CardTitle>
                        <CardText key={`SkeletonItem-series-meta-` + i} className={classnames(styles['item-meta'])}></CardText>
                        <CardText key={`SkeletonCard-series-description-` + i} className={classnames(styles['card-description'])}></CardText>
                        <CardText key={`SkeletonItem-series-presenter-` + i} className={styles['presenter']}>
                          <div key={`SkeletonItem-series-presenterSpan` + i} className={styles['presenter-span']} ></div>
                          <div key={`SkeletonItem-series-companyLogo` + i} className={styles['body-comp-logo']}></div>
                        </CardText>
                      </div>
                    </CardBody>
                  </Card>
                </CardDeck>
              </Col>
            </Row>
          );
        }
        return SkeletonCard;
      })()}
    </>
  );
};

export default SkeletonSeriesSearchGridItems;
