import styles from './index.module.scss';

const GoogleAds = ({ adUnitPath, adSize }) => {
  return (
    <>
      {/* {adSlotId && (
        <div
          id={adSlotId}
          style={{ width: adSize[0], height: adSize[1], background: 'gray' }}
        ></div>
      )} */}

      <div
        id={`div-gpt-ad-${adSize[0]}-try-${adSize[1]}-0`}
        className={styles[`div-gpt-ad-${adSize[0]}-try-${adSize[1]}-0`]}
        style={{ minWidth: '300px', minHeight: '50px' }}
      >
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `googletag.cmd.push(function() { googletag.display('div-gpt-ad-${adSize[0]}-try-${adSize[1]}-0'); });`,
          }}
        ></script>
      </div>
    </>
  );
};

export default GoogleAds;
