import React from 'react';
// import Container from '@bt-react/components/Container'
import styles from './index.module.scss';
import classnames from 'classnames';
import { Container } from 'reactstrap';

import nextCookies from 'next-cookies';

// Components
import MetaHead from '../components/MetaHead/MetaHead';
import HeroBanner from '../components/HeroBanner/HeroBanner';
import BusinessProducts from '../components/BusinessProducts';

// Libraries
import LatestTalks from '../libs/latestTalks/latestTalks';
import fetchUserProfile from '../libs/fetchUserProfile';
import getBaseUrl from '../libs/getBaseUrl';
import FetchSummits from '../libs/FetchSummits/FetchSummits';
import FetchSeries from '../libs/FetchSeries/FetchSeries';
import FetchTopicsTables from '../libs/FetchTopicsTables/FetchTopicsTables';
import FetchChannels from '../libs/FetchChannels/FetchChannels';

function Index({ hostUrl, summitUrl, seriesUrl, staticAssetUrl }) {
  const talksFilterParams = {
    size: 8,
    rank: 'closest',
    q: '',
    contentType: 'webcast',
    bq: `(and type:'webcast')`,
    rankClosest: 'abs(entrytime-time())',
    returnFields: 'closetime,entrytime',
  };

  const summitsFilterParams = {
    size: 6,
    rank: 'closest',
    bq: "(and type:'summit')",
    rankClosest: 'abs(entrytime-time())',
  };

  const seriesFilterParams = {
    size: 6,
    rank: 'closest',
    bq: "(and type:'series')",
    rankClosest: 'abs(entrytime-time())',
  };

  const channelsFilterParams = {
    size: 4,
    rank: '-duration',
    bq: "(and type:'channel')",
    rankClosest: '',
  };

  // UTM params for channels featured webinar grid.
  const channelsFeaturedWebinarUTM =
    '?utm_source=brighttalk-portal&utm_medium=web&utm_campaign=homepage&utm_content=channels-grid';

  // UTM params for series grid.
  const seriesUTM =
    '?utm_source=brighttalk-portal&utm_medium=web&utm_campaign=homepage&utm_content=series-grid';

  // UTM params for summits grid.
  const summitsUTM =
    '?utm_source=brighttalk-portal&utm_medium=web&utm_campaign=homepage&utm_content=summit-grid';

  // UTM params for talks grid.
  const talksUTM =
    '?utm_source=brighttalk-portal&utm_medium=web&utm_campaign=homepage&utm_content=talk-grid';

  const talksComponentInfo = {
    talksFilterParams,
    talksUTM,
    priorityStatus: true,
    sectionHeading: true,
    sectionHeadingTitle: 'See latest talks',
    sectionHeadingDescription:
      'Stay current with free webinars, videos and trainings from industry experts.',
    sectionHeadingLink: '/all-talks',
    sectionHeadingLinkText: 'See all talks',
    sectionHeadingBddTitle: 'see-latest-talks-title',
    sectionHeadingBddDesc: 'see-latest-talks-description',
    sectionHeadingBddLink: 'see-all-talks-link',
    skeletonHeadingDescription: true,
    certificateLink: false,
  };

  const summitsComponentInfo = {
    sectionHeadingTitle: 'Attend BrightTALK Summits',
    sectionHeadingDescription:
      'Virtual events showcasing talks from experts in your industry.',
    sectionHeadingLink: '/summits',
    sectionHeadingLinkText: 'See all summits',
    sectionHeadingBddTitle: 'see-latest-summits-title',
    sectionHeadingBddDesc: 'see-latest-summits-description',
    sectionHeadingBddLink: 'see-all-summits-link',
    priorityStatus: true,
    summitUrl,
    summitsUTM,
    footLinkTitle: 'See agenda',
    gridItemName: 'summit-item',
    dataBddTitle: 'talks-title-summits',
    dataBddDesc: 'talks-card-description-summits',
    rowColSm: 12,
    rowColMd: 6,
    rowColXl: 4,
    sectionHeading: true,
  };

  const seriesComponentInfo = {
    sectionHeadingTitle: 'Watch episodic series',
    sectionHeadingDescription: 'Don’t miss the latest episodes.',
    sectionHeadingLink: '/series',
    sectionHeadingLinkText: 'See all series',
    sectionHeadingBddTitle: 'see-latest-series-title',
    sectionHeadingBddDesc: 'see-latest-series-description',
    sectionHeadingBddLink: 'see-all-series-link',
    priorityStatus: true,
    seriesUrl,
    seriesUTM,
    footLinkTitle: 'Watch series',
    gridItemName: 'series-item',
    dataBddTitle: 'talks-title-series',
    dataBddDesc: 'talks-card-description-series',
    rowColSm: 12,
    rowColMd: 6,
    rowColXl: 4,
    sectionHeading: true,
  };

  const channelsComponentInfo = {
    utmParams: channelsFeaturedWebinarUTM,
    footLinkTitle: 'View channel',
    headingBlockName: 'homepage-channels-block',
    sectionHeading: true,
    sectionHeadingTitle: 'Discover trending channels',
    sectionHeadingDescription: 'Check out top content creators on BrightTALK.',
    sectionHeadingLink: '/channels',
    sectionHeadingLinkText: 'See all channels',
    sectionHeadingBddTitle: 'see-latest-channels-title',
    sectionHeadingBddDesc: 'see-latest-channels-description',
    sectionHeadingBddLink: 'see-all-channels-link',
    skeletonHeadingDescription: true,
    priorityStatus: true,
    gridCounter: 4,
    rowColSm: 12,
    rowColLg: 6,
    //rowColXl: 6,
    gridItemName: 'channel-page-channels',
    pageType: 'homepage-channels',
    api: '/api/channels',
  };

  return (
    <>
      <MetaHead
        pageName="Homepage"
        title="BrightTALK - Discover and learn with the world’s brightest B2B professionals"
        hostUrl={hostUrl}
        staticAssetUrl={staticAssetUrl}
      />

      <div className={styles.app}>
        <HeroBanner
          pageType="homepage-banner"
          title="Discover and learn with the world's brightest B2B professionals"
          description="Access expertise from thousands of leading companies and millions of registered users"
          search={true}
          theme={'light'}
          hostUrl={hostUrl}
        />

        <Container
          className={classnames(
            styles['portal-container'],
            'container-homepage'
          )}
        >
          {LatestTalks(hostUrl, talksFilterParams, talksComponentInfo)}
          {FetchSummits(hostUrl, summitsFilterParams, summitsComponentInfo)}
          {FetchSeries(hostUrl, seriesFilterParams, seriesComponentInfo)}
          {FetchChannels(hostUrl, channelsFilterParams, channelsComponentInfo)}
          {FetchTopicsTables(10)}
          <BusinessProducts sectionHeading={true} />
        </Container>
      </div>
    </>
  );
}

// This gets called on every request
export async function getServerSideProps(context) {
  /**
   * This is the test code to check whether the 500 page is displaying properly when there is an trigger.
   * It can be seen by passing the query params test500 in the URL
   * E.g - https://www.int05.brighttalk.net/?test500=true
   */
  if (context?.query?.test500 == 'true') {
    throw new Error('Internal Server Error');
  }

  const { BTSESSION } = nextCookies(context);

  const response = await fetchUserProfile(encodeURIComponent(BTSESSION));

  if (response?.isLoggedIn) {
    return {
      redirect: {
        permanent: false,
        destination: '/mybrighttalk/my-feed/',
      },
    };
  }

  // Pass data to the page via props
  return {
    props: {
      hostUrl: getBaseUrl(),
      summitUrl: global.process.env.SUMMITS_HOST,
      seriesUrl: global.process.env.SERIES_HOST,
      staticAssetUrl: global.process.env.STATIC_ASSETS_CDN_URL,
    },
  };
}

export default Index;
