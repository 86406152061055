import React, { FC } from 'react'
import {Row, Col } from 'reactstrap'
import SectionHeading from '../SectionHeadings/SectionHeadings'
import BusinessCard from '../BusinessCard/BusinessCard'

interface BusinessProductsProps {

  sectionHeading?: boolean
}

const BusinessProducts: FC<BusinessProductsProps> = ( {sectionHeading}) => {

  return (
   <>
    <div>

      { sectionHeading == true &&
        <SectionHeading
          title='Learn about our business products'
          description='Create content and build demand with our all-in-one platform.'
          link='https://www.brighttalk.com/business/why-brighttalk'
          linkTitle='Why BrightTALK'
          headingBlockName={'business-block'}
          linkNewTab={true}
          dataBddTitle={'business-products-title'}
          dataBddDesc={'business-products-description'}
          dataBddLink={'business-products-link'}
        />
      }

      <Row xs="1" md="2">
        <Col className="mb-4">
          <BusinessCard
            title='Webinar Platform'
            description='Easy to use. Extensible. Incredibly productive.'
            link='https://www.brighttalk.com/business/products/channels'
          />
        </Col>
        <Col className="mb-4">
          <BusinessCard
            title='Demand Generation'
            description='Engage more of B2B’s most active audiences.'
            link='https://www.brighttalk.com/business/products/lead-generation'
          />
        </Col>
      </Row>
      <Row xs="1" md="2">
        <Col className="mb-4">
          <BusinessCard
            title='Virtual Events'
            description='Scale and polish you can depend on.'
            link='https://www.brighttalk.com/business/products/virtual-events'
          />
        </Col>
        <Col className="mb-4">
          <BusinessCard
            title='Video Production'
            description='Ensuring your brand stands out.'
            link='https://www.brighttalk.com/business/products/studios'
          />
        </Col>
      </Row>
    </div>
   </>
  );
}

export default BusinessProducts
