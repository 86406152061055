import React from 'react';

import useSWR from 'swr';
import ContainerTopics from '../../components/ContainerTopics/ContainerTopics';

// Libraries
import swrUrlParser from '../swrUrlParser/swrUrlParser';
import useSWRFetcher from '../useSWRFetcher';

const FetchTopics = (type, size) => {
  const url = '/api/topics';

  const params = {
    type: type,
    size: size,
  };

  const fullUrl = swrUrlParser(url, params);

  const { data, error } = useSWR(fullUrl, useSWRFetcher());

  let topicsData;

  if (error) return {};

  if (!data) {
    topicsData = {
      data: [],
    };
  } else {
    topicsData = {
      title: type == 'popular' ? 'Most popular' : 'Trending',
      type: type,
      data: data['res'],
    };
  }

  return topicsData;
};

function FetchTopicsTables(size) {
  const mostPopularTopics = FetchTopics('popular', size);
  const trendingTopics = FetchTopics('trending', size);

  return (
    <ContainerTopics
      mostPopularData={mostPopularTopics}
      trendingData={trendingTopics}
      sectionHeadingTitle="Browse content by topics"
      sectionHeadingDescription="Discover the most popular and trending topics on BrightTALK."
      sectionHeadingLink="/topics/most-popular"
      sectionHeadingLinkText="See all topics"
      sectionHeading={true}
      headingBlockName={'topics-block'}
      sectionHeadingBddTitle={'see-latest-topics-title'}
      sectionHeadingBddDesc={'see-latest-topics-description'}
      sectionHeadingBddLink={'see-all-topics-link'}
    />
  );
}

export default FetchTopicsTables;
