import React, { useState } from 'react';

import { Form, Input, Button } from 'reactstrap';
import styles from './index.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';

const SearchButton = ({baseUrl}) => {

  const [query, setQuery] = useState('');

  const handleParam = (setValue) => (e) => setValue(e.target.value);

  const handleSubmit = (e) => {
	  e.preventDefault();
    // Redirect only if there is a value in the search field.
    // Ref ticket - https://brighttalktech.jira.com/browse/APF-2003 => Use the plain JS redirection instead of the router push method as it breaks the page when hit the browser back button.
    if(e?.target?.q?.value){
      window.location.href = `${baseUrl}/search?q=${e?.target?.q?.value}`
    }
  };

  return (
    <Form onSubmit={handleSubmit} data-bdd="search-form">
      <div className={styles['search-bar']} data-bdd="search-form-label">
        <Input
          className="search-bar-input sc-search-bar"
          data-bdd="search-form-input"
          name="q"
          value={query}
          onChange={handleParam(setQuery)}
          aria-label="Search Input Field"
          placeholder="Search for topics that matter most to your business"
          type="text"
        />
        <Button
          type="submit"
          aria-label="Search Button"
          className="search-bar-button sc-search-bar"
          data-bdd="search-form-submit"
        >
          <FontAwesomeIcon icon={faMagnifyingGlass} />
        </Button>
      </div>
    </Form>
  );
};

export default SearchButton;
