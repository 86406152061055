import React, { FC } from 'react';
import { Container, Row, Col } from 'reactstrap'; // we need this to make JSX compile
import Topics from '../../components/Topics/Topics';
import SectionHeadings from '../SectionHeadings/SectionHeadings';

export interface TopicsProps {
  mostPopularData: TopicsArray;
  trendingData: TopicsArray;
  sectionHeading: true | false;
  headingBlockName?: string;
  sectionHeadingTitle?: string;
  sectionHeadingDescription?: string;
  skeletonHeadingDescription?: boolean;
  sectionHeadingLink?: string;
  sectionHeadingLinkText?: string;
  sectionHeadingBddTitle?: string;
  sectionHeadingBddDesc?: string;
  sectionHeadingBddLink?: string;
  isFirstBlock?: boolean;
}

export interface TopicsArray {
  title: string;
  type: 'popular' | 'trending';
  data: [];
}

const ContainerTopics: FC<TopicsProps> = ({
  mostPopularData,
  trendingData,
  sectionHeading,
  headingBlockName,
  sectionHeadingTitle,
  sectionHeadingDescription,
  skeletonHeadingDescription,
  sectionHeadingLink,
  sectionHeadingLinkText,
  sectionHeadingBddTitle,
  sectionHeadingBddDesc,
  sectionHeadingBddLink,
  isFirstBlock,
}) => {

  return (
   <>

    { sectionHeading && (

     <SectionHeadings
      title={sectionHeadingTitle}
      description={sectionHeadingDescription}
      link={sectionHeadingLink}
      linkTitle={sectionHeadingLinkText}
      headingBlockName={headingBlockName}
      dataBddTitle={sectionHeadingBddTitle}
      dataBddDesc={sectionHeadingBddDesc}
      dataBddLink={sectionHeadingBddLink}
      classes="topics-heading"
      //{... (isFirstBlock == true &&  {classes: "no-margin-top" } ) }
     />

    )}

    <Row xs="1" md="2" data-bdd="topics-tables-homepage">
      <Col data-bdd="topics-popular-homepage">
        <Topics
          title={mostPopularData['title']}
          type={mostPopularData['type']}
          data={mostPopularData['data']}
        />
      </Col>
      <Col data-bdd="topics-trending-homepage">
        <Topics
          title={trendingData['title']}
          type={trendingData['type']}
          data={trendingData['data']}
        />
      </Col>
    </Row>
   </>
  );
};

export default ContainerTopics;
